.profile-page {
  .profile-page__content {
    .profile-info {
      .picker-radio {
        display: inline-block;
      }
      .date_of_birth {
        .byear,
        .bmonth,
        .bday {
          width: 30%;
          float: left;
          margin: 0;
          clear: none;
          padding-right: 1em;
        }
      }
    }
  }
  .profile-info__text__header {
    @include swap_direction(padding, 1.3rem 0);
  }
}

.address-book-page__content {
  &.panel {
    .address-book {
      &.address-book__registration_address_panel {
        margin: 0;
        padding-right: 0;
        float: right;
        .address-item__controls {
          .delete-address {
            display: none;
          }
        }
      }
    }
  }
}

.address-form {
  &__item {
    .default_shipping_label {
      label {
        display: inline-block;
      }
    }
  }
}
