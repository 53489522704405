.site-header {
  li.site-navigation__item,
  li.site-navigation__more {
    @include breakpoint($bp--xlarge-up) {
      @include swap_direction(padding, 0 16px);
    }
  }
  .site-header__prefix {
    @include breakpoint($width-xxlarge) {
      width: 121px;
      margin-#{$ldirection}: 12px;
    }
    .site-logo {
      margin-#{$ldirection}: 2px;
      .site-logo__image {
        @include breakpoint($width-xxlarge) {
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
  .site-header__menu {
    @include breakpoint($width-xxlarge) {
      width: 864px;
      margin-#{$ldirection}: 172px;
    }
    .site-header__extra {
      @include breakpoint($width-xxlarge) {
        float: #{$rdirection};
      }
      .header-search__icon {
        .icon--search {
          .viewcart &,
          .checkout & {
            line-height: 60px;
          }
        }
      }
    }
  }
  .site-header__suffix {
    @include breakpoint($width-xxlarge) {
      width: 250px;
    }
    .site-header__tools {
      .site-my-mac {
        &__link {
          @include heading-mono;
          .viewcart &,
          .checkout & {
            @include breakpoint($bp--large-up) {
              display: block;
            }
          }
        }
      }
      .site-email-signup {
        @include breakpoint($width-xxlarge) {
          @include swap_direction(padding, 0 14px);
          @include swap_direction(margin, 0);
          min-width: 0;
        }
        &__link {
          &-icon {
            .viewcart &,
            .checkout & {
              line-height: 60px;
            }
          }
        }
      }
      .block-template-site-my-mac-v1 {
        @include breakpoint($width-xxlarge) {
          width: 43%;
        }
      }
    }
  }
  .site-header__tools {
    .site-my-mac__link {
      font-size: 1rem;
    }
  }
  li.site-navigation__item.is-dropdown {
    .site-navigation__dropdown {
      .site-navigation__item:last-child {
        .site-navigation__submenu {
          left: 0;
        }
      }
    }
  }
}

.product__footer {
  .product_content_fav_mobile {
    width: 100%;
    float: #{$ldirection};
  }
}

.product--teaser {
  .product__add-to-bag {
    @include swap_direction(padding, 0 20px);
  }
}

.waitlist-iframe-wrapper {
  width: 100%;
  height: 90%;
}

.tiny-waitlist-overlay {
  .email_input {
    float: #{$ldirection};
    width: 80%;
    height: inherit;
    margin-bottom: 10px;
  }
  .terms_condition_cont {
    clear: both;
  }
  .waitlist_thankyou_heading {
    @include swap_direction(margin, 10px 0);
  }
  .submit_input {
    .input-btn {
      float: #{$rdirection};
      height: 58px;
      margin-bottom: 10px;
    }
  }
}

.page--spp__product {
  .product--not-shoppable.prod_inv_status-3 {
    .product__footer {
      .product__inventory-status.temp-out-of-stock_notifyme-spacing {
        @include swap_direction(margin, 0 0 25px 0);
        .coming-soon_text {
          width: 100%;
        }
      }
    }
  }
}

.product--not-shoppable.prod_inv_status-3 {
  .product__footer {
    .product__inventory-status {
      .coming-soon {
        display: block;
      }
    }
  }
}

.grid--mpp__item {
  .prod_inv_status-5.product--not-preorder {
    .inactive {
      @include swap_direction(padding, 0 0 0 15px);
      display: block;
      line-height: 40px;
    }
  }
  .product--teaser {
    .product__footer {
      .site-container & {
        .product__inventory-status {
          @include swap_direction(padding, 0);
          .temp-out-of-stock__text {
            margin-top: 40px;
            position: absolute;
            #{$rdirection}: 0;
          }
          a.notify_me {
            @include breakpoint($bp--small-up) {
              @include swap_direction(padding, 0px 15px);
              width: auto;
              position: static;
              line-height: 30px;
              font-size: 15px;
              display: inline-block;
              margin: 0px;
              height: 30px;
              float: #{$rdirection};
              clear: none;
            }
            @include breakpoint($bp--large-down) {
              float: #{$ldirection};
            }
          }
          a.notify_me:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.site-container {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          @include swap_direction(padding, 0 13px);
        }
      }
    }
  }
}

.logo_space {
  .empty,
  .samples-page,
  .account-order-history {
    margin-bottom: 80px;
  }
}

#main_content {
  margin-bottom: 80px;
}

.product--full {
  &.product--shadegrid {
    .product__footer {
      .product_content_fav_mobile {
        border-top: none;
      }
    }
  }
  .product__footer {
    .product_content_fav_mobile {
      border-top: none;
    }
  }
}

.artistry-video-section__grid {
  .grid--item {
    &:nth-of-type(2n) {
      @include breakpoint($bp--medium-up) {
        float: #{$ldirection};
      }
    }
  }
}

.site-footer--sticky {
  &.drawer-container {
    .trustmark-logo {
      position: absolute;
      bottom: 50px;
      right: 5px;
    }
  }
}

.mpp-custom {
  .mpp-custom__header {
    &.mpp-custom__header--no-border {
      border-top: $border;
    }
  }
}

.layout--artistry-artist {
  .product--teaser--tiny {
    &.prod_inv_status-3,
    &.prod_inv_status-2 {
      .product__footer {
        .product__size {
          padding-bottom: 10px;
        }
      }
    }
  }
}

.prod_inv_status-2.product--not-preorder .temp-out-of-stock,
.prod_inv_status-3.product--not-preorder .coming-soon,
.prod_inv_status-5.product--not-preorder .inactive,
.prod_inv_status-7.product--not-preorder .sold-out {
  .artist--products__carousel-slide & {
    display: block;
  }
}

.custom-grid {
  .carousel {
    &.carousel--has-dots {
      .slick-dots {
        bottom: 0;
      }
    }
  }
}

.p-w-r {
  .form-group {
    &.pr-wheredidyoupurchasethisproduct-form-group {
      display: none;
    }
    &.pr-wheredidyoupurchasethisproductmy-form-group {
      display: block;
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .pr-war {
        #pr-war-form {
          .pr-wheredidyoupurchasethisproductmy-form-group {
            display: block;
          }
        }
      }
    }
  }
}

.product-full__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet {
        .pr-snippet-read-and-write {
          a {
            &.pr-snippet-review-count {
              display: block;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}

.contact-us-page {
  margin-bottom: 80px;
}
