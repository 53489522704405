.appt-book {
  .service-select {
    .service {
      height: auto !important;
      .selection-bar {
        .selection-data {
          .selection-time {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $width-xlarge) {
  .appt-book {
    .service-select {
      .service {
        min-height: 380px;
        height: auto !important;
        .selection-bar {
          .selection-data {
            position: relative;
          }
          .selection-selector {
            position: relative;
          }
          .selection-data__button {
            float: none;
          }
        }
      }
    }
  }
}
