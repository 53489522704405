#ot-sdk-btn {
  &.ot-sdk-show-settings-btn {
    background: none;
    border: none;
    padding: 0;
    text-decoration: none;
    border-bottom: 1px solid $color--gray--lightest;
    &:hover {
      color: $color--gray;
    }
  }
  &.ot-sdk-show-settings-btn-en {
    background: none;
    border: none;
    padding: 0;
    text-decoration: none;
    border-bottom: 1px solid $color--gray--lightest;
    &:hover {
      color: $color--gray;
    }
  }
  &.ot-sdk-show-settings-btn-my {
    background: none;
    border: none;
    padding: 0;
    text-decoration: none;
    border-bottom: 1px solid $color--gray--lightest;
    &:hover {
      color: $color--gray;
    }
  }
}
