.checkout {
  &.shipping {
    .checkout-progress {
      li {
        &.shipping {
          font-weight: bold;
        }
      }
    }
  }
  &.review {
    .checkout-progress {
      li {
        &.review {
          font-weight: bold;
        }
      }
    }
    .checkout__content {
      @include swap_direction(padding, 1em);
    }
  }
  &.confirm {
    .checkout-progress {
      li {
        &.confirm {
          font-weight: bold;
        }
      }
    }
    #confirmation-number {
      margin: 15px 0;
    }
    .btn {
      font-size: 18px;
    }
    #print-order {
      max-width: 100%;
      .print-buttons {
        text-align: #{$rdirection};
        padding-right: 0.5em;
      }
    }
  }
  .checkout-header__title {
    width: 100%;
    padding: 1em 0;
  }
  .checkout_top {
    padding: 0 1em;
    border-bottom: 1px solid $color--gray--lightest;
    header {
      .messages {
        background: none;
        padding: 0;
        border: none;
      }
    }
    #top-viewcart-buttons {
      .continue-buttons {
        .btn {
          max-width: inherit;
          width: auto;
        }
      }
    }
    .go-shopping {
      margin-top: 10px;
    }
    &.checkout-viewcart-bottom-buttons {
      padding-right: 0;
      .viewcart-buttons {
        width: 100%;
      }
      .viewcart-buttons-panel {
        border-bottom: none;
      }
    }
    .dbMsgBox {
      margin-top: 5px;
    }
    .viewcart-buttons {
      width: 75%;
      padding-right: 1em;
      .viewcart-buttons-panel {
        position: relative;
        .continue-buttons {
          text-align: #{$rdirection};
          .continue-checkout {
            width: auto;
            max-width: inherit;
          }
        }
        .continue-shopping {
          position: absolute;
          top: 35px;
        }
      }
    }
    .shop_count {
      text-align: center;
      margin-bottom: 10px;
      .checkout__panel-title {
        display: inline;
      }
    }
  }
  .checkout_shippng_top {
    padding-bottom: 1em;
    .checkout-header__title {
      @include swap_direction(padding, 0 0 1em);
    }
    .checkout-header {
      border-bottom: none;
    }
    .checkout-submit {
      width: 75%;
      text-align: #{$rdirection};
      padding-right: 1em;
      .checkout-buttons {
        margin-bottom: 10px;
      }
    }
  }
  .checkout_review_top {
    .picker-checkbox {
      margin-top: 0;
    }
    .review-panel {
      padding-top: 0;
    }
    .review-instructions {
      p {
        margin-bottom: 1em;
      }
    }
  }
  &.viewcart {
    .checkout__content {
      @include swap_direction(padding, 0 1em);
    }
  }
  .checkout-progress {
    @include swap_direction(margin, 30px 0);
    list-style-type: decimal;
    li {
      @include swap_direction(margin, 0 30px 0 20px);
      float: left;
      width: auto;
    }
  }
  .left {
    &.checkout__content {
      .cart-items {
        .sub__total {
          @include h6();
          padding: 20px 7% 5px 0;
          text-align: #{$rdirection};
          border-top: 1px solid $color--gray--lightest;
        }
        .sub_total_value {
          @include h6();
        }
      }
      .bottom-viewcart-buttons {
        position: relative;
        .continue-buttons {
          text-align: #{$rdirection};
          .continue-checkout {
            width: auto;
          }
        }
        .continue-shopping {
          position: absolute;
          top: 25px;
        }
      }
      .guarantee-panel {
        margin-bottom: 15px;
      }
      .recommended-products-panel {
        @include layout($grid--3, 1em) {
          .recommended-item {
            &:nth-of-type(3n + 1) {
              @include grid-span(1, 1);
              clear: both;
              position: relative;
            }
            &:nth-of-type(3n + 2) {
              @include grid-span(1, 2);
            }
            &:nth-of-type(3n + 3) {
              @include grid-span(1, 3);
            }
          }
        }
        display: block;
      }
    }
    .checkout-review-index-header {
      @include swap_direction(margin, 20px 0 75px);
      border-bottom: none;
      .checkout-page-title {
        @include swap_direction(margin, 0);
      }
    }
    .review-instructions {
      padding-top: 1em;
    }
    .trans_detail_item {
      @include swap_direction(margin, 0 25px 25px 0);
      float: #{$ldirection};
      position: relative;
      width: 29%;
      word-wrap: break-word;
      .checkout__subtitle {
        margin-bottom: 10px;
      }
      .change_link {
        position: absolute;
        right: 0;
        top: 4px;
      }
    }
    .shipping-panel-wrapper {
      .shipping-panel {
        header {
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid $color--gray--lightest;
        }
      }
    }
    .checkout_complete {
      .legal_statement {
        @include swap_direction(padding, 20px);
        border: 1px solid $color--gray--lightest;
        height: 170px;
        overflow-x: hidden;
        overflow-y: scroll;
      }
      .radio_group {
        @include swap_direction(margin, 1em 0);
        .picker-radio {
          display: inline-block;
          .picker-label {
            margin-right: 5px;
          }
        }
      }
    }
    .bottom-continue-checkout-btn {
      margin-bottom: 20px;
    }
    .sms-promotions-container {
      text-transform: uppercase;
      input[type='text'] {
        margin-top: 5px;
      }
    }
    .shipping-page-info-panel {
      .submit {
        text-align: #{$rdirection};
      }
      .address-form {
        .form-item {
          width: 50%;
          float: left;
          &.left {
            clear: left;
            padding: 0;
          }
          &.right {
            padding-left: 1.2em;
          }
          select {
            height: 56px;
          }
        }
      }
      .checkout-header__title {
        padding-left: 0;
        width: auto;
      }
      .delivery-info-content {
        .select2-container {
          width: 100%;
        }
      }
      .checkout__panel-title {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color--gray--lightest;
        display: block;
      }
      .margin_top_bottom {
        margin: 10px 0;
      }
      label {
        display: inline-block;
      }
      .panel {
        border-bottom: none;
      }
      .shipping-info {
        .address_controls {
          #choose-address {
            width: 24em;
          }
          .select2-container {
            width: 100%;
          }
        }
      }
      .shipping-address-info {
        padding-top: 0;
      }
      .shipping-info,
      .billing-info {
        .address-container {
          @include swap_direction(margin, 0 4em 0.8em 1.5em);
          float: #{$ldirection};
          width: 15em;
        }
        .address_controls {
          float: #{$ldirection};
        }
      }
      .billing-info {
        padding-bottom: 0;
        .address-container {
          margin-bottom: 0;
        }
      }
      input[type='checkbox'] {
        height: 20px;
        width: 20px;
        border-radius: 3px;
        border: 1px solid $color--gray--lighter;
        float: left;
        margin: 0 15px 0 2px;
      }
      input[type='radio'] {
        height: 20px;
        width: 20px;
        border-radius: 10px;
        border: 1px solid $color--gray--lighter;
        float: left;
        margin: 0 5px 0 1px;
      }
      .my_add {
        margin-bottom: 10px;
      }
      input[type='radio'] ~ label,
      input[type='radio'] ~ .label {
        display: inline-block;
        padding-left: 10px;
        line-height: 20px;
      }
    }
  }
  .checkout__sidebar {
    @include swap_direction(padding, 1em);
    @include breakpoint($bp--largest-up) {
      margin-bottom: 1em;
    }
    @include breakpoint($bp--xxlarge-up) {
      @include swap_direction(padding, 0);
    }
    .order-summary-panel {
      .order-summary__content {
        .order_status {
          @include h6();
          width: 100%;
          .price {
            text-align: #{$rdirection};
          }
        }
      }
    }
    .offer_submit {
      text-align: center;
    }
    .links-panel {
      margin: 1em 0;
    }
  }
  .samples-page {
    .samples-panel {
      margin: 0 15px;
    }
    .sample-header {
      text-align: center;
    }
    .samples-continue {
      float: #{$rdirection};
      margin: 2em 0;
      width: 230px;
      .form-submit {
        width: 100%;
      }
    }
    .samples-top {
      text-align: center;
      .samples-buttons {
        border-top: 1px solid $color--gray--lightest;
        .return-link {
          float: #{$ldirection};
          margin-top: 46px;
        }
      }
    }
    .samples-buttons.bottom {
      .return-link {
        float: #{$ldirection};
        margin-top: 46px;
      }
      .add-button {
        float: #{$rdirection};
        width: 230px;
        margin: 2em 0;
      }
    }
    .samples-grid {
      .samples-grid--item {
        .product__select {
          label {
            display: block;
            float: #{$rdirection};
          }
          .sample-select-button {
            width: 130px;
          }
          .picker-handle {
            display: none;
          }
          .picker {
            margin-right: 0;
          }
        }
        input[type='checkbox'] {
          position: absolute;
          left: -9999px;
          display: none;
        }
      }
    }
  }
  .offer-code-panel__title {
    .title-icon {
      @include icon(arrow--down);
      float: #{$rdirection};
    }
    &.open .title-icon {
      @include icon(arrow--up);
    }
  }
  &.shipping,
  &.review {
    li[id='offer_bogo..'] {
      display: none;
    }
  }
}

.viewcart {
  .site-footer {
    display: block;
  }
}

.order-details-page {
  @include swap_direction(padding, 1em 1em 8em);
  .cart_sample {
    width: 70%;
  }
}

.samples {
  &-grid--item {
    .product__select {
      .sample-input {
        display: none;
      }
    }
  }
}

.section-eye-makeup {
  .quicklinks-tout {
    &___over_img-inner {
      font-family: $roboto-mono_regular-font;
    }
  }
}
