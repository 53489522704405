@if $password_strengthen {
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($bp--medium-up) {
        position: absolute;
        #{$ldirection}: 102%;
        bottom: -1%;
        width: 200px;
        &-reset {
          bottom: auto;
          top: 41%;
          #{$ldirection}: 28%;
        }
        &-checkout {
          bottom: calc(60% - 50px);
          #{$ldirection}: 98%;
        }
        &-book_appoint {
          bottom: auto;
          -webkit-transform: translateY(-92%);
          -moz-transform: translateY(-92%);
          -o-transform: translateY(-92%);
          transform: translateY(-92%);
          position: absolute;
          #{$ldirection}: 64%;
        }
      }
      width: 100%;
      background-color: $color--white;
      z-index: 99;
      margin-top: 5px;
      font-size: 10px;
      &:before {
        @include breakpoint($bp--medium-up) {
          content: '';
          position: absolute;
          top: 43%;
          #{$rdirection}: 100%;
          border-width: 5px;
          border-style: solid;
          border-color: transparent $color--gray--dark transparent transparent;
        }
      }
    }
    &__rules {
      padding: 10px;
      font-size: 1em;
      list-style: none;
    }
  }
}

.profile-password-update {
  &__rules {
    li {
      color: $color--gray--dark;
    }
    font-size: 11px;
    margin-#{$ldirection}: -13px;
  }
  .form-submit {
    &:disabled,
    &.disabled {
      border: 1px solid $color--gray;
    }
    width: 18%;
  }
}

.password-reset-page {
  .password-reset {
    position: relative;
    .password-field__info-reset {
      @include breakpoint($bp--medium-up) {
        top: 70px;
        #{$ldirection}: 200px;
        &:before {
          top: -11px;
          #{$rdirection}: 60%;
          border-color: transparent transparent $color--gray--dark transparent;
        }
      }
      @include breakpoint($width-xlarge) {
        top: -21px;
        #{$ldirection}: 326px;
        &:before {
          top: 43%;
          #{$rdirection}: 100%;
          border-color: transparent $color--gray--dark transparent transparent;
        }
      }
    }
  }
}

.appt-book-section {
  .password-field {
    &__info {
      @include breakpoint($bp--medium-up) {
        #{$ldirection}: 82%;
        bottom: auto;
        position: absolute;
        transform: translateY(-92%);
        -webkit-transform: translateY(-92%);
        -moz-transform: translateY(-92%);
        -o-transform: translateY(-92%);
      }
    }
  }
  .booking-step {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          #{$ldirection}: 82%;
          bottom: auto;
          position: absolute;
          transform: translateY(-92%);
          -webkit-transform: translateY(-92%);
          -moz-transform: translateY(-92%);
          -o-transform: translateY(-92%);
        }
      }
    }
  }
}
